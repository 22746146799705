'use strict';

var base = require('core/product/base');
var zonos = require('../zonos');
var Extend = window.Extend;
var siteIntegrations = require('core/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    // conditional added for response, sometimes it was failing when called on page load
    if (response) {
        $('.minicart').trigger('count:update', response);
        var messageType = response.error ? 'alert-danger' : 'alert-toast-messaging';
        // show add to cart toast
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Updates slider for PDP main images and thumbnails from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createSlider(images, assets, videos, $productContainer) {
    var $sliderContainers = $productContainer.find('.primary-images .slider-container');
    var data = images !== null ? {images} : null;
    data.assets = assets || null;
    data.videos = videos || null;

    // Reversing order in which to update sliders so that thumbnails get initialized first
    $($sliderContainers.get().reverse()).each((index, sliderContainer) => {
        var $slider = $(sliderContainer).find('.slider');
        $slider.trigger('slider:update', data);
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, currentTarget) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    var isSetItem = $productContainer.hasClass('product-set-item-detail') ? true : false;

    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources, currentTarget);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var pdpGalleryAssets = response.product.pdpGalleryAssets;
    var vimeoVideos = response.vimeoVideos;
    var exclusionData = response.product.exclusionData;
    var $oldWishlistIcon = $productContainer.find('.primary-images-main .wishlist-toggle-product').clone(true);

    this.createSlider(primaryImages, pdpGalleryAssets, vimeoVideos, $productContainer);
    // if variant is a product set item, update the sample image
    if (isSetItem) {
        $productContainer
            .find('.product-set-item-main-image')
            .attr('src', primaryImages.large[0].url)
            .attr('alt', primaryImages.large[0].alt);
    }

    // Update Product Name and Description
    var productName = response.product.productName;
    var headline = response.product.headlineDescription;

    if (productName) {
        $productContainer.find('.product-name').empty().html(productName);
    }
    if (headline) {
        $productContainer.find('.product-description-below-product-name span').empty().html(headline);
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $oldWishlistIcon;
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);
        $newWishlistIcon.attr('data-addmessage', response.product.wishlistMessages.addTitle);
        $newWishlistIcon.attr('data-removemessage', response.product.wishlistMessages.removeTitle);

        //Make heart icon accurate
        var wishlist = require('core/wishlist/components/helpers.js');
        wishlist.updateLinkData($newWishlistIcon);

        $productContainer.find('div.primary-images-main .wishlist-toggle-product').replaceWith($newWishlistIcon);
    }

    // Update Exclusion Message
    if (exclusionData.ExcludedFromDiscounts && exclusionData.ExclusionMessage.length > 1) {
        $('#exclusionMessage').empty().html(exclusionData.ExclusionMessage);
    }

    zonos.updateZonosPricing();
}

/**********
 * shared across QV and PDP
 */
function addToCart () {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        // Start RS customization - Complete the Look (Pendant Chain recommendations)
        var $completeTheLookContainer = $(this).closest('.product-detail').find('.js-complete-the-look');
        if ($completeTheLookContainer.length > 0) {
            setPids = [{
                pid: pid,
                qty: quantity
            }];
            $completeTheLookContainer.each((_index, container) => {
                var $container = $(container);
                var $selectedRadios = $container.find('input[type="radio"]:checked');
                $selectedRadios.each((_index, radio) => {
                    var addonPid = $(radio).data('pid');
                    var addonQty = scope.methods.getQuantitySelected($(this));
                    if (addonPid && addonQty) {
                        setPids.push({
                            pid: addonPid,
                            qty: addonQty
                        });
                    }
                });
            });
            pidsObj = JSON.stringify(setPids);
        }
        // End RS customization - Complete the Look (Pendant Chain recommendations)

        // Begins RS Customization - Personalization
        var personalizationDetails = {
            groups: {}
        };

        $('.js-customization-input').each(function() {
            var input = $(this);
            var groupName = input.data('input-group');
            if (!personalizationDetails.groups[groupName]) {
                personalizationDetails.groups[groupName] = {
                    inputs: {},
                    style: null
                };
            }
            personalizationDetails.groups[groupName].inputs[input.attr('name')] = {
                value: input.val(),
                lineItemLabel: input.data('line-item-label')
            };
        });

        $('.js-style-select:checked').each(function() {
            var selectedStyle = $(this);
            var groupName = selectedStyle.data('input-group');
            if (personalizationDetails.groups[groupName]) {
                personalizationDetails.groups[groupName].style = selectedStyle.val();
                personalizationDetails.groups[groupName].styleLabel = selectedStyle.attr('data-style-label');
            }
        });
        // End RS Customization - Personalization

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer),
            customizations: JSON.stringify(personalizationDetails) // RS Personalization
        };

        // BEGIN Extend integration
        var EXT_PDP_UPSELL_SWITCH = window.EXT_PDP_UPSELL_SWITCH || undefined;
        var isPlanSelected = false;
        form.area = 'product_page';
        form.component = 'buttons';
        const price = $('#extend-offer').data('price') * 100;
        const category = $('#extend-offer').data('category');

        if ($('#extend-offer').length) {
            var extendComponent = window.Extend.buttons.instance('#extend-offer');
            if (extendComponent != null) {
                var extendPlan = extendComponent.getPlanSelection();

                if (extendPlan) {
                    form.extendPlanId = extendPlan.planId;
                    form.extendPrice = extendPlan.price;
                    form.extendTerm = extendPlan.term;
                    form.price = price;
                    form.category = category;
                    isPlanSelected = true;
                    $(this).trigger('updateAddToCartFormData', form);
                }
            }
            if (EXT_PDP_UPSELL_SWITCH && !isPlanSelected) {
                $('body').trigger('extend:modal:viewed',
                    { productId: form.pid, area: 'product_modal' });

                Extend.modal.open({
                    referenceId: $('.product-detail').data('pid').replace(/_/g, ' '),
                    category: category,
                    onClose: function (plan) {
                        if (plan) {
                            form.extendPlanId = plan.planId;
                            form.extendPrice = plan.price;
                            form.extendTerm = plan.term;
                            form.area = 'product_modal';
                            form.price = price;
                            form.category = category;
                            form.component = 'modal';
                            $(this).trigger('updateAddToCartFormData', form);
                        }

                    if (addToCartUrl) {
                        $.ajax({
                            url: addToCartUrl,
                            method: 'POST',
                            data: form,
                            success: function (data) {
                                handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                $('body').trigger('personalization:afterSuccess');
                                $.spinner().stop();
                                scope.methods.miniCartReportingUrl(data.reportingURL);
                            },
                            error: function () {
                                $.spinner().stop();
                            }
                        });
                    }
                }
            });
            return;
        }
    }
        // END Extend integration

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    $('body').trigger('personalization:afterSuccess');
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

function handleCompleteTheLookRadios() {
    var $completeTheLookContainer = $('.js-complete-the-look');
    if ($completeTheLookContainer.length > 0) {
        $completeTheLookContainer.each((_index, container) => {
            var $container = $(container);
            var $radios = $container.find('input[type="radio"]');
            $radios.on('change', _event => {
                var $selectedRadios = $radios.filter(':checked:not([id^="none"])');
                var $noneRadio = $radios.filter('[id^="none"]').closest('.custom-radio');
                if ($selectedRadios.length > 0) {
                    $noneRadio.removeClass('d-none');
                }
            });
        });
    }
}
// START RS Customization: PDP Custom Variant Dropdown
function getLongestStringLengthByColumnType($cells) {
    var maxCharLength = 0;

    $cells.each(function() {
        var charLength = 0;
        if ($(this).data('cell-type') === 'price') {
            charLength = parseInt($(this).find('.value').text().trim().length, 10);
        } else {
            charLength = parseInt($(this).text().trim().length, 10);
        }
        if (charLength > maxCharLength) {
            maxCharLength = charLength;
        }
    });

    return maxCharLength;
}
function handleCustomSelectPicker() {
    $(document).on('click', ".custom-select-picker .dropdown-item", function () {
        var isCurrentSelection =  $(this).attr("data-selected");
        if (isCurrentSelection !== "true") {
            var value = $(this).data("value");
            var originalSelectId = $(this)
                .closest(".custom-select-picker")
                .attr("data-controls");
            var $originalSelectNode = $("#" + originalSelectId);

            // Update the button label to the selected option's display value
            var $dropdownButton = $(this)
                .closest(".custom-select-picker")
                .find(".dropdown-toggle");
            var $dropdownButtonLabel = $dropdownButton.find('.dropdown-toggle-label');
            var buttonHtml = $(this).html();
            $dropdownButtonLabel.html(buttonHtml);

            $originalSelectNode.find("option").each(function () {
                if ($(this).data("attr-value") === value) {
                    $(this).prop("selected", true);
                    $dropdownButtonLabel.css('grid-template-columns', $dropdownButtonLabel.data('grid-column-sizes'));
                } else {
                    $(this).prop("selected", false);
                }
            });

            // Update the original select node (hidden in DOM)
            var $productContainer = $(this).closest('.product-detail');
            var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
            if (isChoiceOfBonusProducts) {
                var currentTarget = $(this).parents('.attribute').find('select[class*="select-"], .options-select').find('option[data-attr-value="'+$(this).data('attr-value')+'"]');
                base.methods.attributeSelect(currentTarget.val(), $productContainer, currentTarget);
            } else {
                $originalSelectNode.trigger("change");
            }

            // Update data-selected for dropdown-items
            var $dropdownMenu = $(this).closest('.dropdown-menu');
            $dropdownMenu.find('.dropdown-item').each(function () {
                $(this).data('selected', false).attr('data-selected', 'false');
            });
            $(this).data('selected', true).attr('data-selected', 'true');
        }
    });

    // Initial setup
    $(".custom-select-picker").each(function () {
        var $dropdownButton = $(this).find(".dropdown-toggle");
        var $dropdownButtonLabel = $dropdownButton.find('.dropdown-toggle-label');
        var $selectedItem = $(this).find(
            '.dropdown-item[data-selected="true"]'
        );
        var buttonHtml;
        var buttonCss;

        // Generate colummn sizes
        var $dropdownDisplayNameCells = $(".custom-select-picker").find('.js-custom-select-displayname');
        var $dropdownPriceCells = $(".custom-select-picker").find('.js-custom-select-price');
        var $dropdownAvailabilityCells = $(".custom-select-picker").find('.js-custom-select-availability');
        var maxDisplayNameLength = getLongestStringLengthByColumnType($dropdownDisplayNameCells);
        var maxPriceLength = getLongestStringLengthByColumnType($dropdownPriceCells);
        var maxAvailabilityLength = getLongestStringLengthByColumnType($dropdownAvailabilityCells);

        var dropdownWidth = `${maxDisplayNameLength + maxPriceLength + maxAvailabilityLength}ch`
        $('.custom-select-picker').css('width', dropdownWidth);
        $('.custom-select-picker .dropdown-item:not(.reset)').css('grid-template-columns', `${maxDisplayNameLength}fr ${maxPriceLength}fr ${maxAvailabilityLength}fr`);
        $('.custom-select-picker .dropdown-toggle-label').data('grid-column-sizes', `${maxDisplayNameLength}fr ${maxPriceLength}fr ${maxAvailabilityLength}fr`);

        // Check if there is a preselected item
        if ($selectedItem.length) {
            buttonHtml = $selectedItem.html();
            buttonCss = $selectedItem.css('grid-template-columns');
        } else {
            // Fallback to the first item if no preselected item found
            buttonHtml = $(this).find(".dropdown-item:first").html();
        }

        // Update the dropdown button label
        $dropdownButtonLabel.html(buttonHtml);
        $dropdownButtonLabel.css('grid-template-columns', buttonCss);
    });
}

function onQuickViewOpen() {
    $(document).on('quickview:ready', function() {
        handleCustomSelectPicker();
    })
}
// END RS Customization: PDP Custom Variant Dropdown

// RS Customization: PDP Custom Variant Radios in leu of OOTB SFRA Dropdown
function handleCustomRadioPicker() {
    $('body').on('click', '.js-radio-vip-select input[type="radio"]', function() {
        var selectedValueId = $(this).data('value-id');
        var optionId = $(this).prop('name');
        var $parentNodeSelect = $('div[data-attr="'+optionId+'"] .custom-select');
        if ($parentNodeSelect) {
            $parentNodeSelect.val($parentNodeSelect.find('option[data-attr-value="' + selectedValueId + '"]').val());
            $parentNodeSelect.change();
        }
    });
}
// END RS Customization: PDP Custom Variant Radios in leu of OOTB SFRA Dropdown

// RS Customization: PDP Radio Button Product Option Select
function handleRadioOptions() {
    $('body').on('change', '.js-radio-options-select input[type="radio"]', function() {
        var selectedValueId = $(this).data('value-id');
        var optionId = $(this).prop('name');
        var $parentNodeSelect = $('div[data-option-id="'+optionId+'"] .options-select');
        if ($parentNodeSelect) {
            $parentNodeSelect.val($parentNodeSelect.find('option[data-value-id="' + selectedValueId + '"]').val());
            $parentNodeSelect.change();
        }
    })
}
// END RS Customization: PDP Radio Button Product Option Select

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    var methods = this;

    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <div class="modal-title m-auto">' + data.labels.selectprods + '</div>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = methods.parseHtml(response.renderedTemplate);
            var count = 0;
            $('#chooseBonusProductModal .modal-header .modal-title').text(response.promoTitle);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body).prepend(`<div class="col-12">${response.promoDescription}</div>`);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');

            // LBH update - auto-select first variant for each bonus product
            $('#chooseBonusProductModal .product-detail').each((_index, element) => {
                var $productContainer = $(element);
                count++;
                var $variantSelect = $productContainer.find('.attribute select');
                if (!$variantSelect.find('option:selected').data('attr-value')) {
                    $variantSelect.val($variantSelect.find('option[data-attr-value]').first().val());
                    $variantSelect.trigger('change');
                }
            });

            if (count < 2) {
                $('#chooseBonusProductModal').addClass('narrow').find('.bonus-product-item').removeClass('col-6').addClass('col-12');
            }

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

function selectBonusProduct() {
    $(document).on('click', '.select-bonus-product', function () {
        var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
        var pid = $(this).data('pid');
        var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
        var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
        var totalQty = 0;
        $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
            totalQty += $(this).data('qty');
        });
        totalQty += submittedQty;
        var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
        var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
        if (totalQty > 0) {
            $('.add-bonus-products').removeClass('disabled'); //if a bonus product is selected make sure to enable the atc button
        }
        if (totalQty <= maxPids) {
            var selectedBonusProductHtml = ''
            + '<div class="selected-pid" '
            + 'data-pid="' + pid + '"'
            + 'data-qty="' + submittedQty + '"'
            + 'data-optionID="' + (optionID || '') + '"'
            + 'data-option-selected-value="' + (valueId || '') + '"'
            + '>'
            + '<div class="bonus-product-name">'
            + $choiceOfBonusProduct.find('.product-name').html()
            + '</div>'
            + '<div class="remove-bonus-product"></div>'
            + '</div>'
            ;
            $('#chooseBonusProductModal .selected-bonus-products .bonus-summary-products-container').append(selectedBonusProductHtml);
            $('.pre-cart-products').html(totalQty);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            $('body').trigger('modal:loaded', $('#chooseBonusProductModal')); // update quickview modal scroll height
        } else {
            $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
        }

        // LBH update - trigger add to cart when only eligible for one bonus product
        if (totalQty === 1) {
            $('#chooseBonusProductModal .add-bonus-products').trigger('click');
        }
    });
}

function updateAddToCart() {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;
        // update local add to cart (for sets)
        $productContainer.find('button.add-to-cart').attr('disabled',
            (!response.product.readyToOrder || !response.product.available));
        // update global add to cart (single products, bundles)
        var $dialog = $(response.$productContainer).closest('.quick-view-dialog');

        // LBH Customization - on Wish List page we use quickWishlistUpdate (quickAddToCart but with the wishlist update button and the 'quick-update-wishlist' added)
        // therefore, run the else clause for wishlist updates
        if ($dialog.length && !$dialog.find('.quick-update-wishlist')){
            $dialog.find('.add-to-cart-global, .update-cart-product-global').attr('disabled',
                !$dialog.find('.global-availability').data('ready-to-order')
                || !$dialog.find('.global-availability').data('available')
            );
            console.log(!$dialog.find('.global-availability').data('ready-to-order')
            || !$dialog.find('.global-availability').data('available'));
        } else {
            var enable = $productContainer.find('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });
            $productContainer.find('button.add-to-cart-global, button.btn-update-wishlist').attr('disabled', !enable);
        }
    });
}


function onRecommenderSliderReady() {
    $(document).on('targetslider:ready', function(event, object) {
        var closestParent = $('#' + object.id).closest('.recommender-row');

        if (closestParent.length > 0) {
            var $preloaderNode = closestParent.find('.targetslider-loading-container');
            var $renderedNode = closestParent.find('.targetslider-container');
            $preloaderNode.fadeOut(300, function() {
                $renderedNode.removeClass('d-none').fadeIn(300);
            });
        }
    });

    // Set a timeout to check if the skeleton is still visible after 5 seconds
    window.onload = function() {
        // Set a timeout to check if the skeleton is still visible after 10 seconds
        setTimeout(function() {
            $('.recommender-row').each(function() {
                var $container = $(this);
                var $preloaderNode = $container.find('.targetslider-loading-container');

                // If the preloader is still visible, hide the container
                if ($preloaderNode.is(':visible')) {
                    $container.fadeOut(300);
                }
            });
            zonos.updateZonosPricing();
        }, 10000); // 10 seconds timeout
    };
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs, currentTarget) {
    var viewOutOfStockItems = window.CachedData.siteIntegrations.viewOutOfStockItems;
    var $attr = '.custom-select[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + '.select-' + $.escapeSelector(attr.id) + ' option:first-child');
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;

    $defaultOption.attr('value', attr.resetUrl).attr('disabled', true);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        var currentSelectedOption = $(currentTarget).find('option:selected');

        if (!viewOutOfStockItems && (!attrValue.selectable || !attrValue.available)) {
            if (!attrValue.selectable) {
                $attrValue.attr('disabled', true);
            }
            //check if selected value is now unavailable, if so select the default option
            if (currentSelectedOption.data('attr-value') == attrValue.value) {
                $attrValue.removeAttr('selected');
                $($attr).prop('selectedIndex', 0);
            }
            // append a msg to option to tell user its not available with selected options
            $attrValue.html(attrValue.displayValue + msgs.unavailableMsg);
        } else {
           $attrValue.html(attrValue.displayValue);
            if (currentSelectedOption.text() == attrValue.displayValue) {
                if (isChoiceOfBonusProducts) {
                    $productContainer.find('a.dropdown-item[data-attr-value="'+currentSelectedOption.data('attr-value')+'"]').attr('data-selected',true);
                    var $dropdownButtonLabel = $productContainer.find(".custom-select-picker").find(".dropdown-toggle").find('.dropdown-toggle-label');
                    var buttonHtml = $productContainer.find('a.dropdown-item[data-attr-value="'+currentSelectedOption.data('attr-value')+'"]').html();
                    $dropdownButtonLabel.html(buttonHtml);
                }
                $(currentSelectedOption).attr('selected', 'selected');
                $productContainer.find($attr).prop('selectedIndex', $(currentSelectedOption).index());
            }
        }
        $attrValue[attrValue.available ? 'addClass' : 'removeClass']('available');
        $attrValue.removeClass('unavailable out-of-stock');

        if (!attrValue.available) {
            $attrValue.addClass(toggleObject.viewOutOfStockItems ? 'out-of-stock' : 'unavailable');
            $attrValue.append(' ' + msgs.unavailableMsg);
        }
    });
}

base.methods.createSlider = createSlider;
base.methods.handleVariantResponse = handleVariantResponse;
base.methods.handlePostCartAdd = handlePostCartAdd;
base.methods.chooseBonusProducts = chooseBonusProducts;
base.methods.processNonSwatchValues= processNonSwatchValues;
base.addToCart = addToCart;
base.handleCompleteTheLookRadios = handleCompleteTheLookRadios;
base.handleCustomSelectPicker = handleCustomSelectPicker;
base.handleRadioOptions = handleRadioOptions;
base.selectBonusProduct = selectBonusProduct;
base.handleCustomRadioPicker = handleCustomRadioPicker;
base.onClosingChooseBonusProductModal = null; // removes toast warning when no bonus products are selected
base.onQuickViewOpen = onQuickViewOpen;
base.onRecommenderSliderReady = onRecommenderSliderReady;
base.updateAddToCart = updateAddToCart;

module.exports = base;
